import { createReducer } from '@reduxjs/toolkit';
import { Address } from 'interfaces';
import {
  init,
  setaddress,
  setcoords,
  setdelivery,
  addExtraInfo,
} from './delivery.actions';

const deliveryInitialState: {
  delivery: boolean;
  address: Address;
} = {
  delivery: true,
  address: {
    id: 0,
    user_id: 0,
    name: '',
    district: '',
    address: '',
    civic_number: '',
    city_code: '',
    latitude: 0,
    longitude: 0,
    extra_info: '',
    favourite: false,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  } as Address,
};

export const deliveryReducer = createReducer(deliveryInitialState, builder =>
  builder
    .addCase(init, () => deliveryInitialState)
    .addCase(setaddress, (state, action) => {
      const extra_info = action.payload.extra_info ?? state.address.extra_info;
      return {
        ...state,
        address: { ...state.address, ...action.payload, extra_info },
      };
    })
    .addCase(setdelivery, (state, action) => {
      return { ...state, delivery: action.payload };
    })
    .addCase(addExtraInfo, (state, action) => {
      return {
        ...state,
        address: { ...state.address, extra_info: action.payload },
      };
    })
    .addCase(setcoords, (state, action) => {
      return {
        ...state,
        address: {
          ...state.address,
          latitude: action.payload.lat,
          longitude: action.payload.lng,
        },
      };
    }),
);
